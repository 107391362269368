import expiredIcon from "assests/images/expired.png";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useLocale } from "lib/hooks/useLocale";

interface CheckoutExpiredProps {
  isInvalidUser: boolean;
}

const CheckoutExpired = ({ isInvalidUser }: CheckoutExpiredProps) => {
  const { t } = useLocale();
  const {
    actions: { logout },
  } = useAuthContext();
  
  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex flex-col items-center space-y-3 rounded-xl border border-[#EFD080] bg-[#FDFBF6] py-4 px-2">
        <img src={expiredIcon} alt="expire" className="h-8 w-8" />
        <div className="w-full space-y-2 text-center">
          <h2 className="text-medium font-semibold text-carbon-dark">
            {isInvalidUser ? t("invalid_user") : t("link_expired")}
          </h2>
          <p className="text-sm text-coal-light">
            {isInvalidUser ? t("invalid_user_description") : t("link_expired_description")}
          </p>
          {isInvalidUser && (
            <div className="">
              <PrimaryButton
                buttonText="Logout"
                className="w-full mt-4"
                onClick={() => {
                logout();
                window.location.href = window.location.href;
              }}>
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutExpired;
