import { useLocale } from "lib/hooks/useLocale";
import ErrorScreenImage from "assests/images/error-screen.png";

export const ErrorScreen = () => {
  const { t } = useLocale();
  return (
    <div className="flex h-full w-full flex-col justify-center items-center">
       <div className="px-5 flex flex-col items-center">
        <img src={ErrorScreenImage} alt={"error-image"} height="300" width="300" />
        <h1 className="text-[1.5rem] font-semibold text-coal-dark mt-2">{t("error_screen_title")}</h1>
        <p className="text-center font-normal text-sm leading-relaxed text-coal-dark mt-2">{t("error_screen_description")}</p>
         <button className="flex cursor-pointer items-center justify-center mt-4 px-10 py-4 bg-carbon-dark text-white rounded-2xl text-base font-semibold" onClick={() => window.location.reload()}>
         {t("error_screen_btn_title")}
         </button>
       </div>
    </div>
  );
};
