import React, { useEffect, useState, useRef, useCallback } from "react";
import { withScope } from "@sentry/react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocale } from "lib/hooks/useLocale";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { putRequest, publicFetcher } from "lib/core/apiClient";
import { getItems, initializeCheckout, hasAutoAppliedCoupon, getTotalItems, redirectOnSuccessfulOrder, handleSuccessRedirection, getCheckoutExpiredParams } from "lib/utils/checkout";
import CheckoutItems from "components/checkout/CheckoutItems";
import BillingDetails from "components/checkout/BillingDetails";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import Coupons from "components/checkout/Coupons";
import Accordion from "components/common/Accordian";
import {
  inIframe,
  setPhoneNumberSpacing,
  isEmptyObj,
  isMobile,
  exitCheckout,
  roundStringNumber,
  setPageClarityTags,
  publishPostMessage,
  handlePaymentSuccessRedirection,
  addHttpIfMissing,
} from "lib/utils/helpers";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { parseUserData, setUserOrderHistory } from "lib/utils/user";
import { checkoutSyncPrices } from "lib/core/apiMethods";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, C2P_ERROR_MESSAGES, constants, eventTypes, TOKEN_ERROR } from "lib/utils/constants";
import { ArrowLeft, ChevronDown, ShoppingCart } from "react-feather";
import GenericPopup from "components/common/popups/GenricPopup";
import Celebration from "assests/images/celebration.png";
import GenericBannerStrip from "components/common/notification-strips/GenericBannerStrip";
import Skeleton from "components/common/loaders/Skeleton";
import CheckoutExitPopup from "components/common/popups/CheckoutExitPopup";
import Price from "components/common/Price";
import { UserLoginType, UserType } from "lib/types/user";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import LogoutDialog from "components/checkout/dialogs/LogoutDialog";
import MarketingConsent from "components/common/MarketingConsent";
import SurveyPopup from "components/common/popups/SurveyPopUp";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import CheckoutItemsCount from "components/checkout/CheckoutItemsCount";
import CheckoutExpired from "components/checkout/CheckoutExpired";
import { ErrorScreen } from "components/error-handling/error-screen/ErrorScreen.tsx";
import { t } from "i18next";
import CustomError from "lib/core/customError.ts";
import { CheckoutErrorType, ErrorType } from "lib/types/error.ts";

const AuthenticatedViews = React.lazy(() => import("../components/layout/views/AuthenticatedViews.tsx"));
const UnauthenticatedViews = React.lazy(() => import("../components/layout/views/UnauthenticatedViews.tsx"));

interface MainCheckoutProps {}

let isCheckoutInteractive: boolean = false;
let showOrderSummaryTotalTxt: boolean = false;
let userLoginTypeTemp: UserLoginType;

const MainCheckout: React.FC<MainCheckoutProps> = () => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get("referrer") || undefined;

  const { t } = useLocale();

  const authContext = useAuthContext();
  const {
    state: { isAuthenticated },
    actions: { logout },
  } = authContext;

  const userContext = useUserContext();
  const {
    state: { user, userLoginType, marketingConsent },
    actions: { setUserData, setMarketingConsent },
  } = userContext;

  const checkoutContext = useCheckoutContext();
  const {
    state: {
      billing,
      appliedCoupons,
      checkoutView,
      isTwoStepCheckout,
      exitSurveyOptions,
      checkoutItems,
      isC2P,
      redirectUrl,
      isBuyNow,
      checkoutUIMetadata,
      checkoutExpired,
      isOrderSummaryOpen,
      checkoutItemsMutable,
      initialCheckoutStep,
      orderSummaryStyle,
      checkoutModal,
      showErrorScreen,
    },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setCheckoutView,
      setCheckoutExpired,
      setCheckoutModal,
      setIsOrderSummaryOpen,
      setShowErrorScreen,
    },
  } = checkoutContext;

  const merchantContext = useMerchantContext();
  const {
    state: { merchant },
  } = merchantContext;

  const analytics = useSendAnalyticsEvent();
  const { sendAnalyticsEvent } = analytics;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPayBtnLoading, setIsPayBtnLoading] = useState<boolean>(true);
  const [openCouponPopup, setOpenCouponPopup] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showOrderSummary, setShowOrderSummary] = useState<boolean>(true);
  const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
  const [openConfirmClose, setOpenConfirmClose] = useState<boolean>(false);

  const { hash: hashParam } = useLocation();

  const orderSummaryAccordionRef = useRef<any>(null);
  const totalItems = getTotalItems(checkoutItems);

  const handleClose = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BACK_CLICK,
      eventType: "click",
    });
    exitCheckout(redirectUrl, isBuyNow);
  };

  useEffect(() => {
    if (checkoutView === "PAYMENTS") {
      if (orderSummaryAccordionRef?.current?.closeAccordion) {
        orderSummaryAccordionRef.current.closeAccordion();
      }
    }
    setPageClarityTags(checkoutView);
  }, [checkoutView]);

  useEffect(() => {
    if (inIframe()) {
      publishPostMessage(eventTypes.SET_CART_ITEM_COUNT, { totalItems });
    }
  }, [totalItems]);

  useEffect(() => {
    history.pushState({ checkout: "ongoing" }, "");
  }, []);

  useEffect(() => {
    const backButtonHandler = (event: PopStateEvent) => {
      const isSurveyInvalid =
        merchant?.exitCheckoutAction === "none" ||
        (merchant?.exitCheckoutAction === "survey" && !Boolean(exitSurveyOptions?.length > 0));

      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_BACK_CLICK,
        eventType: "click",
        metaData: {
          bannerData: {
            source: "BROWSER_BACK_BTN",
          },
        },
      });

      if (isOrderSummaryOpen) {
        setIsOrderSummaryOpen(false);
        history.pushState({ checkout: "ongoing" }, "");
        return;
      }
      // close current modal
      //
      if (checkoutModal !== "NONE") {
        setCheckoutModal("NONE");
        history.pushState({ checkout: "ongoing" }, "");
        return;
      }

      if (initialCheckoutStep === "PAYMENTS") {
        if (isSurveyInvalid) {
          handleClose();
        } else {
          setOpenConfirmClose(true);
        }
        return;
      }
      switch (checkoutView) {
        case "PAYMENTS":
          if (!isC2P) {
            setCheckoutView("ADDRESS_LIST");
          }
          break;
        default:
          if (isSurveyInvalid) {
            handleClose();
          } else {
            setOpenConfirmClose(true);
          }
          return;
      }

      history.pushState({ checkout: "ongoing" }, "");
    };

    window.addEventListener("popstate", backButtonHandler);
    return () => {
      window.removeEventListener("popstate", backButtonHandler);
    };
  }, [merchant, checkoutView, checkoutModal, exitSurveyOptions, isC2P, isOrderSummaryOpen]);

  useEffect(() => {
    initialize();
    healthCheck();
  }, [isAuthenticated]);

  const healthCheck = async () => {
    try {
      const response: {
        message: string;
      } = await publicFetcher("/ruok");
      if (response.message !== "imok") {
        throw new Error("Health check failed");
      }
    } catch (err: any) {
      withScope((scope) => {
        scope.setTag("error_type", ErrorType.HEALTH_CHECK_ERROR);
        scope.setExtra("errStatus", err?.response?.status);
        scope.setExtra("errMessage", err?.response?.data?.error ?? err?.message);
        scope.captureException(err);
      });
      setShowErrorScreen(true);
    }
  };

  const initialize = async () => {
    setIsLoading(true);
    try {
      const response = await initializeCheckout({
        tokenId: searchParams.get("tokenId"),
        checkoutIdParam: searchParams.get("checkoutId"),
        oldCheckoutIdParam: searchParams.get("old_checkout_id"),
        analytics,
        userContext,
        authContext,
        checkoutContext,
        merchantContext,
        isCheckoutInteractive,
        hashParam,
        referrer,
      });

      if (response !== undefined) {
        const { checkoutResponse, accountResponse, tokenData } = response;

        // Handling for ACR case
        /**
          *   When order with a specific order is already placed
              API returns order details instead of other attributes
              We are displaying different screen for this case
          */
        if (Boolean(tokenData?.order?.id)) {
          handleSuccessRedirection({
            originUrl: tokenData?.data?.origin_url,
            orderStatusUrl: tokenData?.order?.order_status_url,
            tokenId: tokenData?.uid,
          });
          return;
        }

        showOrderSummaryTotalTxt =
          checkoutResponse?.metadata?.ui_config?.layout?.metadata?.show_order_summary_total ?? false;

        handleResponseSuccess(checkoutResponse, accountResponse, tokenData);

        isCheckoutInteractive = response.isCheckoutInteractive;
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error instanceof CustomError) {
        handleCheckoutError(error);
      }
    }
  };

  const handleCheckoutError = (error: CustomError<CheckoutErrorType>) => {
    const { err, url, payload } = error.extraData;
    if (err?.response?.status === 403) logout();
    else if(TOKEN_ERROR.includes(err?.response?.data?.error)){
      redirectOnSuccessfulOrder({tokenId: searchParams.get("tokenId")});
      return;
    }
    else if (err?.response?.status === 400 && C2P_ERROR_MESSAGES.includes(err?.response?.data?.error)){
      const { expired, isInvalidUser } = getCheckoutExpiredParams(err?.response?.data?.error)
      setCheckoutExpired(expired, isInvalidUser);
    }
    else {
      withScope((scope) => {
        scope.setTag("error_type", ErrorType.CHECKOUT_ERROR);
        scope.setExtra("url", url);
        scope.setExtra("payload", payload);
        scope.setExtra("errStatus", err?.response?.status);
        scope.setExtra("errMessage", err?.response?.data?.error);
        scope.captureException(err);
      });
      setShowErrorScreen(true);
    }
    setIsLoading(false);
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any, tokenData: any) => {
    handleAccountResponse(accountResponse, checkoutResponse, tokenData);
    checkSyncPricesFlag(checkoutResponse);
  };

  const checkSyncPricesFlag = async (checkoutResponse: any) => {
    handleCheckoutResponse(checkoutResponse);
    if (checkoutResponse?.sync_needed && Boolean(checkoutResponse?.addresses)) {
      checkoutResponse = await checkoutSyncPrices(checkoutResponse?.uid);
      handleCheckoutResponse(checkoutResponse);
    }
    setIsPayBtnLoading(false);
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    if (checkoutResponse?.metadata?.ui_config) {
      const availableElements = checkoutResponse?.metadata?.ui_config?.layout?.available_elements;
      if (availableElements) {
        setShowHeader(Boolean(availableElements?.includes("HEADER")));
        setShowOrderSummary(Boolean(availableElements?.includes("ORDER_SUMMARY")));
      }
    }

    updateCheckoutBasedOnCheckoutResponse(checkoutResponse);

    updateMarketingConsent();
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any, tokenData: any) => {
    if (!Boolean(accountResponse) || isEmptyObj(accountResponse)) {
      if (isAuthenticated) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
          eventType: "load",
          metaData: {
            checkoutId: checkoutResponse?.uid,
            checkoutToken: checkoutResponse?.checkout_token_id,
            shopifySessionId: tokenData?.sf_session_id,
            merchantId: checkoutResponse?.merchant_id,
            userData: {
              type: userLoginTypeTemp ?? userLoginType,
              accountResponse: accountResponse ?? {},
            },
            checkoutItems: getItems(checkoutResponse?.items),
          },
          skipState: checkoutResponse?.metadata?.ui_config?.default_view === "PAYMENTS",
        });
      }
      return;
    }

    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserOrderHistory(parsedUserData.uid, checkoutResponse?.merchant_id, checkoutResponse.uid);
    setUserData(parsedUserData);
    setMarketingConsent(true);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_AUTH_LOGIN_COMPLETED,
      eventType: "load",
      metaData: {
        checkoutId: checkoutResponse.uid,
        checkoutToken: checkoutResponse?.checkout_token_id,
        shopifySessionId: tokenData?.sf_session_id,
        merchantId: checkoutResponse?.merchant_id,
        userData: {
          type: userLoginTypeTemp ?? userLoginType,
          userId: parsedUserData.uid,
        },
        checkoutItems: getItems(checkoutResponse.items),
      },
      skipState: checkoutResponse?.metadata?.ui_config?.default_view === "PAYMENTS",
    });
  };

  const closeCouponPopup = () => {
    setOpenCouponPopup(false);
  };

  const updateMarketingConsent = async () => {
    if (marketingConsent === undefined) return;
    if (!Boolean(isAuthenticated)) return;
    try {
      await putRequest("/attributes/v1/account-attributes", {
        attributes: {
          marketing_consent: marketingConsent,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_LOGOUT_BTN_CLICKED,
      eventType: "click",
    });
    if (isTwoStepCheckout && user?.default_shipping_address?.phone) {
      setUserData({
        uid: "",
        email: "",
        phone: "",
        name: "",
        default_billing_address: undefined,
        default_shipping_address: undefined,
        addresses: undefined,
        hasHomeAddr: false,
        hasWorkAddr: false,
      });
      setCheckoutView("ADDRESS_LIST");
      return;
    }
    setShowLogoutDialog(false);
    logout();
  };

  const onOrderSummaryToggle = (isOpen: boolean) => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_ORDER_SUMMARY_BTN_CLICKED,
      eventType: "click",
      metaData: {
        metadata: {
          isOpen: isOpen,
        },
      },
    });
  };

  if(showErrorScreen){
    return <ErrorScreen />;
  }

  if (checkoutExpired) {
    return (
      <div className={`relative w-full ${isMobile() ? "" : "rounded-[28px]"} h-full px-5`}>
        <CheckoutExpired
          isInvalidUser={checkoutExpired?.isInvalidUser}
        />
      </div>
    );
  }

  return (
    <div className={`relative w-full ${isMobile() ? "" : "rounded-[28px]"}`}>
      {!isLoading && (
        <>
          {Boolean(showHeader) && (
            <Header openConfirmClose={openConfirmClose} setOpenConfirmClose={setOpenConfirmClose} />
          )}

          <section
            className={`relative scrollbar-hide w-full overflow-y-auto overflow-x-hidden  ${
              Boolean(showHeader) ? "top-16" : "top-0"
            } ${
              checkoutView === "ADDRESS_LIST"
                ? showHeader
                  ? "h-[calc(100vh-4rem)]"
                  : "h-screen pb-36"
                : "h-screen pb-36"
            }`}>
            <GenericBannerStrip />
            {showOrderSummary && orderSummaryStyle === "TILE" && (
              <Accordion
                id={"order-summary-section"}
                ref={orderSummaryAccordionRef}
                defaultOpen={isOrderSummaryOpen}
                titleIcon={<ShoppingCart className="h-4 w-4 text-coal-dark" strokeWidth={2.5} />}
                titleText={t("order_summary")}
                subTitleText={<CheckoutItemsCount showOrderSummaryTotalTxt={showOrderSummaryTotalTxt} />}
                callbackOnToggle={(isOpen: boolean) => {
                  onOrderSummaryToggle(isOpen);
                  setIsOrderSummaryOpen(isOpen);
                }}
                content={
                  <>
                    {/* Line Items & Bill Summary */}
                    <div className="flex w-full flex-col space-y-6">
                      {Boolean(checkoutItems) && (
                        <CheckoutItems
                          items={checkoutItems ?? []}
                          disableOOSItems={false}
                          checkoutItemsMutable={checkoutItemsMutable}
                        />
                      )}
                      {Boolean(billing) && <BillingDetails {...billing} />}
                    </div>
                  </>
                }
              />
            )}

            {/* Discount Coupons */}

            {(hasAutoAppliedCoupon(appliedCoupons) || merchant?.isDiscountEnabled) &&
              (initialCheckoutStep !== "PAYMENTS" || !isAuthenticated) && (
                <div className="mt-3 px-3 ">
                  <Coupons />
                </div>
              )}
            {isAuthenticated ? (
              <AuthenticatedViews isPrimaryBtnLoading={isPayBtnLoading} />
            ) : (
              <UnauthenticatedViews />
            )}

            {checkoutView === "ADDRESS_LIST" && <MarketingConsent />}

            {(isAuthenticated ||
              (checkoutView !== "ADDRESS_LIST" &&
                isTwoStepCheckout &&
                Boolean(user?.default_shipping_address?.phone))) && (
              <>
                <div
                  className={`flex w-full flex-col items-start px-6 ${
                    checkoutView === "ADDRESS_LIST"
                      ? "py-0 pb-[12.5rem] md:pb-[18.75rem]"
                      : "py-2 md:pb-[9.375rem]"
                  } w-full  space-y-2`}>
                  <div className="flex w-full items-center justify-between">
                    <p className="cursor-pointer py-4 text-sm font-normal text-coal-light">
                      {t("logged_in_as")}
                      <span className="inline-block">
                        {setPhoneNumberSpacing(
                          isTwoStepCheckout ? user?.default_shipping_address?.phone : user?.phone,
                        )}
                      </span>
                    </p>
                    {(checkoutUIMetadata?.layout?.allowLogout === undefined ||
                      checkoutUIMetadata?.layout?.allowLogout) &&
                      !isC2P && (
                        <button
                          className="rounded-lg border border-gray-light bg-gray-lighter p-2 text-xs font-semibold text-coal-dark hover:border-gray-light hover:bg-gray-light"
                          id="flo_logout_btn"
                          onClick={() => {
                            sendAnalyticsEvent({
                              eventName: analyticsEvents.FLO_LOGOUT_CLICKED,
                              eventType: "click",
                            });
                            setShowLogoutDialog(true);
                          }}>
                          {t("logout")}
                        </button>
                      )}
                  </div>
                </div>
              </>
            )}
          </section>
        </>
      )}

      <GenericPopup
        content={
          <>
            {/* Line Items & Bill Summary */}
            <div className="flex w-full flex-col space-y-6">
              <div className="flex w-full flex-col items-center space-y-1">
                <img className="h-12 w-12" src={Celebration} alt="Celebration" />
                <p className="text-lg font-medium text-coal-dark">{t("discount_hurray")}</p>
              </div>
              <div>
                <p className="text-2xl font-medium text-charcoal">
                  <Price total={billing?.discount ?? ""} />
                </p>

                <p className="text-xs font-normal text-coal-light">{t("discount_saved_on_order")}</p>
              </div>
              <div>
                {appliedCoupons?.map((coupon: AppliedCouponType, index: number) => {
                  return (
                    <p
                      key={`${coupon?.code}_${index}`}
                      className="text-sm font-medium uppercase text-coal-dark">
                      {coupon?.code}{" "}
                      <span className="font-normal lowercase text-coal-light">{t("discount_applied")}</span>
                    </p>
                  );
                })}
              </div>
            </div>
          </>
        }
        isOpen={openCouponPopup}
        closePopup={closeCouponPopup}
        height={"h-full"}
        duration={3000}
        width={"w-3/4"}
      />

      <GenericDialog
        isOpen={showLogoutDialog}
        translateAxis="y"
        modalType="NONE"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute">
        <LogoutDialog setIsOpen={setShowLogoutDialog} handleLogout={handleLogout} />
      </GenericDialog>

      {isLoading && <Skeleton />}
    </div>
  );
};

const Header: React.FC<{
  openConfirmClose: boolean;
  setOpenConfirmClose: (state: boolean) => void;
}> = ({ openConfirmClose, setOpenConfirmClose }) => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: {
      exitSurveyOptions,
      redirectUrl,
      isBuyNow,
      isC2P,
      checkoutView,
      billing,
      checkoutItems,
      checkoutItemsMutable,
      isOrderSummaryOpen,
      orderSummaryStyle,
      initialCheckoutStep,
    },
    actions: { setCheckoutView, setIsOrderSummaryOpen },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const orderSummaryHeaderAccordionRef = useRef<any>(null);

  const handleClose = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BACK_CLICK,
      eventType: "click",
    });
    exitCheckout(redirectUrl, isBuyNow);
  };
  const closePopup = () => {
    setOpenConfirmClose(false);
  };

  const handleBackClick = (clickEl: "BACK_BTN" | "LOGO") => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BACK_CLICK,
      eventType: "click",
      metaData: {
        bannerData: {
          source: clickEl,
        },
      },
    });
    if (isOrderSummaryOpen) {
      setIsOrderSummaryOpen(false);
      return;
    }

    if (checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS" && !isC2P) {
      setCheckoutView("ADDRESS_LIST");
      return;
    }
    if (
      merchant?.exitCheckoutAction === "none" ||
      (merchant?.exitCheckoutAction === "survey" && !Boolean(exitSurveyOptions?.length > 0))
    ) {
      handleClose();
      return;
    }
    setOpenConfirmClose(true);
  };

  const onOrderSummaryToggle = (isOpen: boolean) => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_ORDER_SUMMARY_BTN_CLICKED,
      eventType: "click",
      metaData: {
        metadata: {
          isOpen: isOpen,
        },
      },
    });
  };

  return (
    <header
      className={`fixed z-30 flex h-16 w-full flex-row items-center justify-between bg-white px-6 shadow-sm ${
        !inIframe() && "md:w-[25rem] md:rounded-t-[28px]"
      } ${!isMobile() && "rounded-t-[28px]"}`}>
      <div className="flex flex-row items-center">
        <button className="flex cursor-pointer items-center justify-center">
          <ArrowLeft className="mr-2 h-6 w-6 text-coal-dark" onClick={() => handleBackClick("BACK_BTN")} />
          <img
            src={merchant?.logoUrl}
            alt="Brand Logo"
            className="float-left h-10 max-w-[9.375rem]	bg-transparent object-contain"
            onClick={() => handleBackClick("LOGO")}
          />
        </button>
      </div>
      {orderSummaryStyle === "HEADER" ? (
        <div className="flex flex-col">
          <h2 className="mb-1 text-sm font-medium text-coal-light">{t("order_total")}</h2>
          <Accordion
            id={"order-summary-header"}
            ref={orderSummaryHeaderAccordionRef}
            defaultOpen={isOrderSummaryOpen}
            titleIcon={<></>}
            titleText={""}
            subTitleText={
              <h1 className="-mr-1 text-base font-medium text-coal-dark">
                <Price
                  total={roundStringNumber(billing.total_payable) ?? ""}
                  compareAt={
                    Boolean(merchant?.showOriginalPrice) ? billing.original_sub_total : billing.sub_total
                  }
                  orientation="horizontal"
                />
              </h1>
            }
            callbackOnToggle={(isOpen: boolean) => {
              setIsOrderSummaryOpen(isOpen);
              onOrderSummaryToggle(isOpen);
            }}
            content={
              <>
                <div
                  className={`absolute left-0 top-0 flex ${
                    inIframe() ? "" : "md:h-[85vh]"
                  } h-screen w-full bg-black bg-opacity-50 md:w-[25rem]  ${
                    isMobile() ? "md:rounded-b-[28px]" : "md:rounded-[28px]"
                  }`}
                  onClick={() => {
                    orderSummaryHeaderAccordionRef.current.closeAccordion();
                    onOrderSummaryToggle(false);
                  }}
                />
                <div
                  className={`absolute left-0 top-0 flex w-full flex-col overflow-hidden rounded-b-[28px] border-2 border-gray-light ${
                    isMobile() ? "" : "md:rounded-[28px]"
                  } bg-white duration-200 ease-in-out`}>
                  <div
                    className={`flex h-16 w-full flex-row items-center justify-between bg-white px-5 shadow-sm ${
                      !inIframe() && "md:w-[25rem] md:rounded-t-[28px]"
                    } ${!isMobile() && "rounded-t-[28px]"}`}>
                    <div className="flex flex-row items-center">
                      <button className="flex cursor-pointer items-center justify-center">
                        <ArrowLeft
                          className="mr-2 h-6 w-6 text-coal-dark"
                          onClick={() => handleBackClick("BACK_BTN")}
                        />
                        <img
                          src={merchant?.logoUrl}
                          alt="Brand Logo"
                          className="float-left h-10 max-w-[9.375rem]	bg-transparent object-contain"
                          onClick={() => handleBackClick("LOGO")}
                        />
                      </button>
                    </div>
                    <div
                      className="flex flex-col items-end justify-end cursor-pointer"
                      onClick={() => {
                        orderSummaryHeaderAccordionRef.current.closeAccordion();
                        onOrderSummaryToggle(false);
                      }}>
                      <h2 className="mb-1 mr-2 w-fit text-sm font-medium text-coal-light">
                        {t("order_total")}
                      </h2>
                      <h1 className="inline-flex items-center space-x-1 text-base font-medium text-coal-dark">
                        <Price
                          total={roundStringNumber(billing.total_payable) ?? ""}
                          compareAt={
                            Boolean(merchant?.showOriginalPrice)
                              ? billing.original_sub_total
                              : billing.sub_total
                          }
                          orientation="horizontal"
                        />
                        <ChevronDown className="h-4 w-4 rotate-180 cursor-pointer" />
                      </h1>
                    </div>
                  </div>
                  <GenericBannerStrip />
                  <div
                    className={`max-h-[9999px] overflow-hidden pt-2.5 transition-[max-height] duration-700 ease-in`}>
                    {/* Line Items & Bill Summary */}
                    <div className="flex flex-col space-y-6 bg-white">
                      {Boolean(checkoutItems) && (
                        <div className="scrollbar-hide flex max-h-[20rem] overflow-scroll">
                          <CheckoutItems
                            items={checkoutItems ?? []}
                            disableOOSItems={false}
                            checkoutItemsMutable={checkoutItemsMutable}
                          />
                        </div>
                      )}
                      {Boolean(billing) && <BillingDetails {...billing} />}
                    </div>
                  </div>
                </div>
              </>
            }
            customClass="!border-[0px] !-mx-[18px] !mt-0 !w-[6rem] !justify-end"
            buttonClass="!p-0 !h-full !w-[6rem] !justify-end"
            insideClass="!justify-end"
          />
        </div>
      ) : (
        <h1 className="text-base font-medium text-coal-dark">
          <Price
            total={billing.total_payable ?? ""}
            compareAt={Boolean(merchant?.showOriginalPrice) ? billing.original_sub_total : billing.sub_total}
          />
        </h1>
      )}

      {merchant?.exitCheckoutAction === "nudge" && (
        <CheckoutExitPopup closeCheckout={handleClose} isOpen={openConfirmClose} closePopup={closePopup} />
      )}
      {merchant?.exitCheckoutAction === "survey" && Boolean(exitSurveyOptions?.length > 0) && (
        <SurveyPopup closeCheckout={handleClose} isOpen={openConfirmClose} closePopup={closePopup} />
      )}
    </header>
  );
};

export default MainCheckout;
